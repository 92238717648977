<template>
  <v-container style="padding: 0px">
    <v-row>
    <v-col v-if="image" cols="2" md="1" class="item-icon" style="padding:5px">
      <v-row class="h-100" align="center" justify="center">
        <v-col>
          <v-img
            cover
            :src="image ? image : require('../../assets/item-placholder.png')"
            max-width="50"
            max-height="50"
            eager
            style="border-radius:10px"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="image ? 10 : 12" :md="image ? 11:12" class="item-content">
      <v-row no-gutters>
        <v-col cols="10">
          <h3 style>{{ title }}</h3>
        </v-col>
        <v-col cols="2">
          <div class="item-price" v-if="price" style="color:#BDBDBD;float:right;">
            {{
            parseFloat(price)
            .toFixed(2)
            .toString()
            .replace(".", ",")
            }}€
          </div>
          <div v-if="alergen" style="color:#BDBDBD">
            {{alergen}}
          </div>
        </v-col>
      </v-row>
      <v-col style="padding-left: 0">{{ description }}</v-col>
      <br v-if="description == ''" />
      <div class="hr-custom"></div>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["title", "description", "price", "image", "alergen"],
};
</script>

<style>
.hr-custom {
  border-top: 1px solid #e8e8e8;
}
/* .item-content {
  padding-left: 12px;
  padding-top: 20px;
  padding-bottom: 0;
} */
.large-image {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.v-expansion-panel-header--active .item-price {
  padding-right: 10px;
}
</style>